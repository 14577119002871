import React from "react"
import styled from "styled-components"
import Layout from "../../components/layout.js"
import SEO from "../../components/seo.js"
import { InPageNav } from "../../components/navbar.js"
import TheCatch from "../../components/sections/catch.js"
import { McatHero } from "../../components/sections/heroSection.js"
import MCATPricing from "../../components/sections/pricingSection.js"
import {
  ValuePropLeft,
  SwipeSection,
} from "../../components/sections/valuePropSection.js"

// import {CtaCard} from '../../components/sections/sections.js';

//SVGs
import WorkSvg from "../../images/work.svg"
import Savings from "../../images/savings.svg"
import Unparalleled from "../../images/unparalleled.svg"
import Guarantee from "../../images/finish.svg"
import AppSVG from "../../images/applications.svg"

const card1 = {
  packageName: "GET IDEAS",
  price: "150",
  duration: "response",
  description:
    "When you want to write it on your own but don't know where to start or how structure your response",
  onClick: "",
  offered: [
    "Bring your story",
    "Share your trascript and scores",
    "Learn how we'd pitch it",
    // "Resumé analysis",
    // "Transcript analysis",
    // `Formulate your unique story`,
    // "Fitting your story into your response",
    // "Avoiding cliché responses",
  ],
  notOffered: [
    // "Specific feedback on how to write it",
    // "Checking whether you wrote it properly",
  ],
}

const card3 = {
  packageName: "GET AN EARLY DECISION",
  price: "100",
  duration: "response",
  description:
    "We tell you if people with your kinda app. have gotten in -- and whether we think you'll get accepted",
  onClick: "",
  offered: [
    "Checked by multiple experts",
    "Get a thumbs-up or thumbs-down",
    "Includes some feedback",
    // "Implicitly compared to other candidates",
    // "Checked by multiple interviewers",
    // "Specific feedback on response logic",
    // "Specific feedback on story",
    // "Specific feedback on clichés",
    // "Specific feedback on red flags",
    // "Written, detailed commentary",
  ],
  notOffered: [],
}
const card2 = {
  packageName: "LEGGO BERZERK",
  price: "600",
  duration: "250-word response",
  description:
    "We help you craft your story and add the gems; we edit your responses, and vet the whole thing!",
  onClick: "",
  offered: [
    "Includes ABS edits",
    "Admissions-grade responses",
    "Fully vetted",
    "Ready to Submit",

    // "Vet your story",
    // "Vet thoroughness",
    // "(Re)Structuring your responses",
    // "(Re)Structuring your story",
    // "Using words preferred by interviewers",
    // "Have answers that interviewers want",
    // "Avoid all red flags",
  ],
  notOffered: [],
  isRecommended: true,
}

const Ul = styled.ul`
  list-style: none;
  padding: 0;
`
const Li = styled.li`
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: 0;
`
const Bullet = styled.svg`
  display: inline-block;
  position: relative;
  top: 5px;
`
const Text = styled.span`
  font-size: 16px;
  &:before {
    content: "";
    display: inline-block;
    margin-right: 0.5em;
  }
`
// const SeeMore = styled.button`
//   display: block;
//   background: white;
//   padding: 5px 16px;
//   border-radius: 30px;
//   height: 15px;
//   margin: 50px auto 50px auto;
//   outline: none;
// `
const List = ({ children }) => {
  return (
    <Li>
      <Bullet
        height="20"
        width="20"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        stroke="#F50057"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        style={{ display: "inline-block" }}
      >
        <path d="M4 10l4 4 8-9" />
      </Bullet>
      <Text>{children}</Text>
    </Li>
  )
}
const Bolder = ({ children }) => (
  <span style={{ fontWeight: "500" }}>{children}</span>
)

const AppConsulting = ({ location }) => {
  const RedirectToPage = dest => {
    // navigate(dest)
  }

  return (
    <Layout>
      <SEO title="99point9 Applications Editing" />
      <InPageNav
        currentPath={location.pathname}
        pageTitle="Applications Advising"
        navItems={[
          { link: "/editing_applications", label: "Overview" },
          { link: "/editing_applications/how_it_works", label: "How It Works" },
          { link: "/editing_applications/schedule", label: "Class Schedule" },
        ]}
        clickCallback={RedirectToPage}
      />
      <McatHero
        title="Application Advising"
        body={[
          `We help you create `,

          <Bolder>great ideas</Bolder>,
          `, tell the `,

          <Bolder>right story</Bolder>,
          ", write ",

          <Bolder>polished essays</Bolder>,
          ` , & avoid`,
          <Bolder> mistakes.</Bolder>,

          <br />,
          <br />,
          "With our help, often, ",
          <span style={{ color: "#F50057" }}>
            schools don't reject you -- you have enough offers that it's the
            other way round!
          </span>,
        ]}
        ctaPrimary={{
          label: "Sign Up",
          redirect: "/editing_applications/schedule/",
        }}
        ctaSecondary={{ label: "Talk To Us", redirect: "/contact" }}
        img={AppSVG}
        imgWidth="90%"
        imgStyle={{ top: "0px" }}
        backgroundColor="inherit"
      />

      <MCATPricing
        title={"Our Services"}
        card1={card1}
        card2={card2}
        card3={card3}
        noSchedule={true}
      />
      <ValuePropLeft
        caption={" "}
        title={" "}
        figure={[
          <span style={{ color: "#F50057" }}>
            <i>95%</i>
          </span>,
          <br />,
          "ACCEPTANCE RATE",
        ]}
        description={[
          "While we can't guarantee whether you'll def. be accepted,",
          <span style={{ color: "#F50057" }}>
            {" "}
            we have a pretty good track record!
          </span>,
          " We've had students who: ",
          <Ul>
            <List>Gain acceptance the first time</List>
            <List>Tried themselves, but we helped them kill it</List>
            <List>Fixed their apps., and then got in!</List>
          </Ul>,
        ]}
      />
      <ValuePropLeft
        caption={" "}
        title={" "}
        figure={[
          "WE GET ",
          <span style={{ color: "#F50057" }}>
            <i>AD-COMS</i>
          </span>,
        ]}
        description={[
          `We know how admissions committees think, and we know what they're looking for! `,
          <span style={{ color: "#F50057" }}>How?</span>,
          <Ul>
            <List>
              We <b>have</b> yeeears of experience
            </List>
            <List>
              We <b>prepare</b> med., biz., & law students for interviews too
            </List>
            <List>
              We <b>know</b> how to tell a good story
            </List>
            <List>
              We <b>understand</b> their goals
            </List>
            <List>
              We <b>think</b> like they do
            </List>
          </Ul>,
        ]}
      />

      <ValuePropLeft
        caption={" "}
        title={" "}
        figure={[
          "WORRIED?",
          <br />,
          <span style={{ color: "#F50057" }}>DON'T BE!</span>,
        ]}
        description={[
          "To be clear, ",
          <span style={{ color: "#F50057" }}>
            <b>we'll only help you, if you can't do it without us.</b>
          </span>,
          " If you're already doing super well, and you just need some pointers -- don't even worry about it.",
          <br />,
          <br />,
          " That's why 1000s of students seek our guidance cz we can give them an objective view of what they need to fix, and why they shouldn't be worried.",
          <br />,
          <br />,
          "So, reach out and feel free to ask like ",
          <span style={{ color: "#F50057" }}>whatever!</span>,
        ]}
      />
      <TheCatch title={["You", "gotta", "meet", "da", "cut-offs,", "qurl."]} />
      {/* <Testimonial
      // refs = {testimonialRef}
      /> */}

      <SwipeSection
        wide
        float={"center"}
        title={["Our Inner Workings"]}
        asides={[
          {
            title: `More than "worth it"`,
            src: Savings,
            alt: "Schedule Image",
            description:
              "Not long ago, we were students too; and we searched for affordable options! We've integrated that sense into our core philosophy: For what you spend, we promise that value you'll get more bang for your buck!",
          },
          {
            title: "We strive for your dream schools",
            src: Unparalleled,
            alt: "Review Sheet Image",
            description:
              "Our team invests in learning your story; we love connecting with you, learning what makes you tick, and living your experiences -- that way we advise you better on expressing your story! We then kinda balance that with what the admissions committees look for.",
          },

          {
            title: "We work our butts off to have you ACCEPTED",
            src: Guarantee,
            alt: "Review Sheet Image",
            description:
              "Our team invests in learning your story, and connecting with you! We learn what makes you tick, what experiences you've forgotten about, and how you should best present yourself -- and we balance that with what the admissions committees look for! That's how we make it happen",
          },
          {
            title: "All hands on deck!",
            src: WorkSvg,
            alt: "Schedule Image",
            description:
              "Our team comprises of multiple experts that weigh in on your app.! Behind the scenes, we have experts in writing, narrative, school-specific admissions, risk management & avoidance, and so much more -- everyone works tirelessly and in unison to give you that edge that you're looking for.",
          },
        ]}
      />
    </Layout>
  )
}

export default AppConsulting
